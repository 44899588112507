.label {
  text-align: left;
  color: #000;
  font-size: 1.1em !important;
  font-weight: bold;  
}

.wrapper{
  padding: 20px;
}

.error{
  color:red;
  font-weight: bold;
}
.addConflictBtn
{
  background-color: #ed7f27;
  border: 2px solid #ed7f27;
  padding: 4px 15px;
  cursor: pointer;
  border-radius: 5px;
  color:white;
  text-align:center;
}
.addConflictBtn:hover
{
  background-color: #ffffff;
  border: 2px solid #ed7f27;
  padding: 4px 15px;
  cursor: pointer;
  border-radius: 5px;
  color:#ed7f27;
  text-align:center;
}