

.header{
  display: flex;
  align-items: center;
  justify-content: space-between;    
  background-color: #3b7bb3 !important;
}

.logo{
  width: 120px;
}

.logo img{
  width: 100%;
}

.user{
  height: 31px;
  display: flex;
  align-items: center;
  margin: 16px 24px 16px 0;
  color: #fff;
}