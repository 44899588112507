.container{
  padding:20px;
  background-color: #fff;
}
.h1{
  font-weight: lighter;
  padding: 0px 20px 0px 0px;
  /* text-align: center; */
  font-size: 2em;
}

p{
  padding: 0px 0px;
}

.label {
  text-align: left;
  color: #000;
  font-size: 1.1em !important;
  font-weight: bold;  
}