.h1{
  font-weight: bold;
  font-size: 20px;
}

.submitConflictBtn
{
  background-color: #ed7f27;
  border: 2px solid #ed7f27;
  padding: 4px 15px;
  cursor: pointer;
  border-radius: 5px;
  color:white;
  text-align:center;
}
.submitConflictBtn:hover
{
  background-color: #ffffff;
  border: 2px solid #ed7f27;
  padding: 4px 15px;
  cursor: pointer;
  border-radius: 5px;
  color:#ed7f27;
  text-align:center;
}
.addConflictBtn
{
  background-color: #3b7bb3;
  border: 2px solid #3b7bb3;
  padding: 4px 15px;
  cursor: pointer;
  border-radius: 5px;
  color:white;
  text-align:center;
}
.addConflictBtn:hover
{
  background-color: #ffffff;
  border: 2px solid #3b7bb3;
  padding: 4px 15px;
  cursor: pointer;
  border-radius: 5px;
  color:#3b7bb3;
  text-align:center;
}