.h1{
  font-weight: bold;
  font-size: 20px;
}


.largeBtn
{
  background-color: #ed7f27;
  padding: 20px 40px;
  width: 100%;
  font-size: 1.4em;
  line-height: 1em;
  cursor: pointer;
  border-radius: 5px;
  color:white;
  text-align:center;
}

.largeBtn:hover
{
  background-color: #ffffff;
  border: 2px solid #ed7f27;
  padding: 20px 40px;
  width: 100%;
  font-size: 1.4em;
  line-height: 1em;
  cursor: pointer;
  border-radius: 5px;
  color:#ed7f27;
  text-align:center;
}

.btnText{
  width: 100%;
  font-size: 1.4em;
  line-height: 1em;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
  text-align:center;
}